import { Icon } from "@chakra-ui/react";

const LogoBlack = () => {
  return (
    <Icon
      viewBox="0 0 369.88524590163934 57.369965204288974"
      width="100%"
      height="100%"
    >
      <g
        transform="matrix(0.6311349252189576,0,0,0.6311349252189576,0,4)"
        fill="#111"
      >
        <path
          xmlns="http://www.w3.org/2000/svg"
          fill="#111"
          d="M81.148,59.837H59.837v-4.92h21.312l0,0c7.697-0.002,13.936-5.519,13.936-13.06  c0-7.544-6.24-13.994-13.938-13.994c-3.875,0-7.377,1.636-9.902,4.218c0.041-0.578,0.068-1.162,0.068-1.754  c0-13.582-10.121-25.411-23.771-25.411c-11.489,0-21.639,8.385-24.564,19.137c-0.941-0.183-1.904-0.289-2.886-0.289  c-8.6,0-15.171,7.355-15.171,15.957s7.371,15.195,15.969,15.195c0.145,0,0.282-0.019,0.424-0.022v0.022h18.852v4.92H21.312  C9.975,59.837,0,51.467,0,40.163c0-11.022,8.751-20.848,19.672-21.312C24.281,7.854,34.956,0,47.54,0  c14.438,0,25.8,10.421,28.632,23.956c1.559-0.421,3.288-1.005,4.977-1.005c10.463,0,18.853,8.665,18.853,18.852  C100.001,51.99,91.611,59.837,81.148,59.837z"
        ></path>
        <polygon
          xmlns="http://www.w3.org/2000/svg"
          fill="#111"
          points="45.902,43.441 54.1,43.441 54.1,68.034 59.837,68.034 50,78.688 40.163,68.034 45.902,68.034 "
        ></polygon>
      </g>
    </Icon>
  );
};

export default LogoBlack;
